import { actionType } from "../../constants/GeneralTasks/costEntering";

const initialState = {
  data: {
    content: [],
    page: {
      number: 0,
      totalPages: 0,
      totalElements: 0,
      size: 10
    }
  },
  list: {
    content: []
  },
  types: {
    content: []
  },
  isFetching: false
};
export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionType.COST_ENTERING_START:
    case actionType.COST_ENTERING_LIST_START:

    case actionType.COST_ENTERING_DELETE_START:
      return {
        ...state,
        data: {
          ...state.data
        },
        isFetching: true,
        error: null
      };
    case actionType.COST_ENTERING:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        },
        isFetching: false,
        error: null
      };
    case actionType.COST_ENTERING_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload
        },
        isFetching: false,
        error: null
      };
    case actionType.COST_ENTERING_TYPE:
      return {
        ...state,
        types: {
          content: action.payload,
          error: null
        }
      };
    default:
      return state;
  }
};
