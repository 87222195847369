import { actionType } from "../../constants/Tasks";
import { keyBy } from "lodash";
const initialState = {
  data: { content: [], page: {} },
  list: [],
  taskIDs: {
    content: []
  },

  taskNames: [],
  taskPaymentMethods: [],
  task: [],
  isFetching: false,
  isWorkinTimeFetching: false,
  isUploadingAttachment: false,
  selectedGeneralTaskType: [
    {
      key: 0,
      text: "All",
      value: 0
    }
  ],
  attachments: [],
  activeItem: null,
  activeTab: null,
  addedType: null,
  tableType: "list"
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.TASKS_TYPE_LIST:
      return {
        ...state,
        selectedGeneralTaskType: action.payload
      };
    case actionType.FETCH_TASKS_START:
      return {
        ...state,
        isFetching: true
      };
    case actionType.FETCH_TASKS:
      return {
        ...state,
        data: action.payload,
        isFetching: false
      };
    case actionType.CREATE_GENERAL_TASK_START:
      return {
        ...state,
        isFetching: true
      };
    case actionType.CREATE_GENERAL_TASK:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(item =>
            item?.id === action.payload?.id ? action.payload : item
          )
        },
        list: state.list.content.some(item => item.id === action.payload.id)
          ? {
              ...state.list,
              content: state.list.content.map(item =>
                item?.id === action.payload?.id ? action.payload : item
              )
            }
          : {
              ...state.list,
              content: [...state.list.content, action.payload]
            },
        isFetching: false
      };
    case actionType.CREATE_GENERAL_TASK_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case actionType.DELETE_GENERAL_TASK_START:
      return {
        ...state,
        isFetching: true
      };
    case actionType.DELETE_GENERAL_TASK:
      const removeTask = keyBy(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(e => !removeTask[e.id])
        },
        isFetching: true,
        activeItem: null
      };
    case actionType.FETCH_GENERAL_TASK_LIST_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case actionType.FETCH_GENERAL_TASK_LIST:
      return {
        ...state,
        list: action.payload,
        isFetching: false,
        error: null
      };
    case actionType.FETCH_GENERAL_TASK_LIST_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case actionType.UPLOAD_CHEMICAL_ATTACHMENT_START:
      return {
        ...state,
        isUploadingAttachment: true
      };

    case actionType.UPLOAD_CHEMICAL_ATTACHMENT:
    case actionType.UPLOAD_CHEMICAL_ATTACHMENT_ERROR:
      return {
        ...state,
        isUploadingAttachment: false
      };

    case actionType.DELETE_ATTACHMENT:
      return {
        ...state,
        attachments: action.payload
          ? state.attachments.filter(
              attachment => attachment.id !== action.payload
            )
          : []
      };

    case actionType.FETCH_TASK_PAYMENT_METHODS_START:
      return {
        ...state,
        taskPaymentMethods: {
          ...state.taskPaymentMethods,
          isFetching: true
        }
      };
    case actionType.FETCH_TASK_PAYMENT_METHODS:
      return {
        ...state,
        taskPaymentMethods: {
          ...state.taskPaymentMethods,
          content: [...action.payload],
          isFetching: false
        }
      };
    case actionType.FETCH_TASK_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        taskPaymentMethods: {
          ...state.taskPaymentMethods,
          isFetching: false
        },
        error: action.payload
      };
    case actionType.FETCH_TASKS_ARCHIVE_START:
      return {
        ...state,
        isFetching: true
      };

    case actionType.FETCH_TASKS_ARCHIVE:
      const deletedElementsIdsMap = keyBy(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(e => !deletedElementsIdsMap[e.id])
        },
        isFetching: true,
        activeItem: null
      };
    case actionType.SET_ACTIVE_ITEM_ID:
      return {
        ...state,
        activeItem: {
          id: action.payload,
          error: null
        }
      };
    case actionType.CHANGE_ACTIVE_TAB:
      return {
        ...state,
        isFetching: action.payload
      };
    case actionType.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload
      };
    case actionType.SET_ADDED_TYPE:
      return {
        ...state,
        addedType: action.payload
      };
    case actionType.SET_TABLE_TYPE:
      return {
        ...state,
        tableType: action.payload
      };
    case actionType.SET_SEEN_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(item =>
            item.id === action.payload
              ? { ...item, seenstatus: item.seenStatus ? false : true }
              : item
          )
        }
      };
    case actionType.FETCH_GENERAL_TASK_IDS_START:
      return {
        ...state,
        taskIDs: {
          ...state.taskIDs,
          isFetching: true
        }
      };
    case actionType.FETCH_GENERAL_TASK_NAMES_START:
      return {
        ...state,
        taskNames: {
          ...state.taskNames,
          isFetching: true
        }
      };
    case actionType.FETCH_GENERAL_TASK_NAMES:
      return {
        ...state,
        taskNames: {
          ...state.taskNames,
          ...action.payload,
          isFetching: false
        }
      };
    case actionType.FETCH_GENERAL_TASK_NAMES_FAIL:
      return {
        ...state,
        taskNames: {
          ...state.taskNames,
          ...action.payload,
          isFetching: false
        }
      };
    case actionType.GET_GENERAL_TASK_WORKING_TIME_START:
      return {
        ...state,
        isWorkinTimeFetching: true
      };
    case actionType.GET_GENERAL_TASK_WORKING_TIME:
      const taskId = Object.keys(action.payload)[0];
      const data = Object.values(action.payload)[0];
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content?.map(item =>
            item.id == taskId
              ? {
                  ...item,
                  workingTimes: data
                }
              : item
          )
        },
        isWorkinTimeFetching: false
      };
    case actionType.FETCH_GENERAL_TASK_START:
      return {
        ...state,
        isFetching: true
      };
    case actionType.FETCH_GENERAL_TASK:
      return {
        ...state,
        task: action.payload,
        isFetching: false
      };
    case actionType.FETCH_GENERAL_TASK_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case actionType.GENERAL_TASK_WORKING_TIME_DELETE_START:
      return {
        ...state,
        isDeleting: true
      };
    case actionType.GENERAL_TASK_WORKING_TIME_DELETE:
      return {
        ...state,
        isDeleting: false,
        data: {
          ...state.data,
          content: state.data.content.filter(c => c.id !== action.payload.id)
        }
      };
    case actionType.GENERAL_TASK_WORKING_TIME_DELETE_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.payload
      };
    case actionType.FETCH_GENERAL_TASK_IDS:
      return {
        ...state,
        taskIDs: {
          ...state.taskIDs,
          ...action.payload,
          isFetching: false
        }
      };
    case actionType.FETCH_GENERAL_TASK_IDS_FAIL:
      return {
        ...state,
        taskIDs: {
          ...state.taskIDs,
          isFetching: false
        },
        error: action.payload
      };
    case actionType.CLEAR_ERROR:
      return {
        ...state,
        error: null
      };

    case actionType.CREATE_GENERAL_TASK_START:
      return {
        ...state,
        isCreating: true
      };
    case actionType.CREATE_GENERAL_TASK:
      return {
        ...state,
        data: initialState.data,
        list: [...initialState.list, action.payload],
        isCreating: false
      };
    case actionType.CREATE_GENERAL_TASK_FAIL:
      return {
        ...state,
        isCreating: false,
        error: action.payload
      };

    case actionType.UPDATE_GENERAL_TASK:
      return {
        ...state,
        data: initialState.data,
        isUpdating: true
      };
    case actionType.UPDATE_GENERAL_TASK_START:
      return {
        ...state,
        isUpdating: false
      };
    case actionType.UPDATE_GENERAL_TASK_FAIL:
      return {
        ...state,
        isUpdating: false,
        error: action.payload
      };
    case actionType.GENERAL_TASK_WORKING_TIME_CREATE_FAIL:
    case actionType.GENERAL_TASK_WORKING_TIME_UPDATE_FAIL:
    case actionType.GET_GENERAL_TASK_WORKING_TIME_FAIL:
    case actionType.DELETE_GENERAL_TASK_FAIL:
    case actionType.FETCH_TASKS_ARCHIVE_FAIL:
    case actionType.FETCH_TASKS_FAIL:
      /*  case actionType.FETCH_CARDS_TASKS_FAIL: */
      return {
        ...state,
        isWorkinTimeFetching: false,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};
